import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Alert as MuiAlert,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";

const CreateTourCost = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const navigate = useNavigate();

  const handleFormSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://jcgnapi.hasthiya.org/tourCost/createTourCost",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      const responseData = await response.json();
      console.log("Response:", responseData);
      if (responseData.status) {
        setAlertSeverity("success");
        setAlertMessage("Tour Cost Added Successfully");
        setTimeout(() => {
          navigate("/tourCost");
        }, 2000);
        resetForm();
      } else {
        setAlertSeverity("error");
        setAlertMessage(responseData.message);
      }
    } catch (error) {
      console.error("Error creating tour cost:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to add tour cost");
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  return (
    <Box
      m="20px"
      height="70vh"
      width="90%"
      overflow="auto"
      paddingRight="20px"
      position="relative"
    >
      <Header title="Create Tour Cost" subtitle="" />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          sx={{ color: "#fff" }}
        >
          <AlertTitle>
            {alertSeverity === "success" ? "Success" : "Error"}
          </AlertTitle>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Typography fontWeight="bold" fontSize="16px">
                Tour Package*
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tourPackage}
                name="tourPackage"
                error={!!touched.tourPackage && !!errors.tourPackage}
                helperText={touched.tourPackage && errors.tourPackage}
              />

              <Typography fontWeight="bold" fontSize="16px">
                Tour Type*
              </Typography>
              <FormControl
                fullWidth
                variant="filled"
                error={!!touched.tourType && !!errors.tourType}
              >
                <InputLabel>Tour Type</InputLabel>
                <Select
                  name="tourType"
                  value={values.tourType}
                  onChange={(e) => setFieldValue("tourType", e.target.value)}
                  onBlur={handleBlur}
                >
                  <MenuItem value="christian trip">Christian Trip</MenuItem>
                  <MenuItem value="regular trip">Regular Trip</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                {touched.tourType && errors.tourType && (
                  <FormHelperText>{errors.tourType}</FormHelperText>
                )}
              </FormControl>

              <Typography fontWeight="bold" fontSize="16px">
                Tour Cost*
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tourCost}
                name="tourCost"
                error={!!touched.tourCost && !!errors.tourCost}
                helperText={touched.tourCost && errors.tourCost}
              />
            </Box>
            {/* Submit Button */}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  marginRight: 2,
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {loading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  tourPackage: yup.string().required("Tour package is required"),
  tourType: yup
    .string()
    .oneOf(["christian trip", "regular trip", "other"], "Invalid tour type")
    .required("Tour type is required"),
  tourCost: yup
    .number()
    .typeError("Tour cost must be a number")
    .required("Tour cost is required"),
});

const initialValues = {
  tourPackage: "",
  tourType: "",
  tourCost: "",
};

export default CreateTourCost;
