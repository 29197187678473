import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import * as yup from "yup";
import { getWorshipCenter, updateWorshipCenter } from "./../../ApiConfig.jsx";

function EditEvent() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [status, setStatus] = useState("active");
  const [userDetails, setUserDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [currentImage, setCurrentImage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchAudio = async (id) => {
    try {
      const data = await getWorshipCenter(id);
      if (data.status) {
        console.log(data.result);
        setStatus(data.result.status);
        setCurrentImage(data.result.imageURL);
        setUserDetails({
          churchName: data.result.church,
          address: data.result.address,
          email: data.result.email,
          tel: data.result.tel,
          noOfUsers: data.result.noOfUsers,
          Date: data.result.Date,
          time: data.result.time,
          longitude: data.result.longtitude,
          latitude: data.result.latitude,
          paster: data.result.paster,
          programs: data.result.programs.join(", "),
          openHours: data.result.openHours,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAudio(id);
  }, [id]);

  const handleFieldChange = (field, value) => {
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [field]: value,
    }));
    validateField(field, value);
  };
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const validateField = (field, value) => {
    const fieldSchema = yup.object().shape({
      churchName: yup.string().required("Name is required"),
      address: yup.string().required("Address is required"),
      email: yup.string().email("Invalid email").required("Email is required"),
      tel: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .max(11, "Phone number must be less than 12 digits")
        .required("Phone No. is required"),

      noOfUsers: yup
        .string()
        .required("No. of users required")
        .matches(/^\d+$/, "No. of users must contain only numbers"),
      Date: yup.string().required("Required"),
      time: yup.string().required("Required"),
      longitude: yup.string().required("Required"),
      latitude: yup.string().required("Required"),
      image: yup.mixed().required("File is required"),
      paster: yup.string().required("Required"),
      programs: yup.string().required("Required"),
      openHours: yup.string().required("Required"),
    });

    fieldSchema
      .validateAt(field, { [field]: value })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
      });
  };

  const updateWorshipCenterHandler = async (userDetails) => {
    if (Object.values(errors).every((error) => error === "")) {
      try {
        setIsLoading(true);
        console.log(
          "🚀 ~ updateWorshipCenterHandler ~ body.userDetails:",
          userDetails
        );
    const formData = new FormData();
    formData.append("church", userDetails.churchName);
    formData.append("address", userDetails.address);
    formData.append("email", userDetails.email);
    formData.append("tel", userDetails.tel);
    formData.append("noOfUsers", userDetails.noOfUsers);
    formData.append("Date", userDetails.Date);
    formData.append("time", userDetails.time);
    formData.append("longtitude", userDetails.longitude);
        formData.append("latitude", userDetails.latitude);
        if (userDetails.image) {
          formData.append("image", userDetails.image);
        }
    formData.append("paster", userDetails.paster);
    formData.append("programs", userDetails.programs);
    formData.append("status", status);
    formData.append("openHours", userDetails.openHours);

        const respondData = await updateWorshipCenter(id, formData);

        if (respondData.status) {
          setAlertSeverity("success");
          setAlertMessage("Worship center edited successfully");
          setTimeout(() => {
            navigate("/worship-centers");
          }, 2500);
        }
      } catch (err) {
        console.log(err);
        setAlertSeverity("error");
        setAlertMessage(`Failed to edit worship center: ${err.message}`);
      } finally {
        setOpenSnackbar(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`Edit Worship Center ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              church name*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.churchName}
              onChange={(e) => handleFieldChange("churchName", e.target.value)}
              error={!!errors.churchName}
              helperText={errors.churchName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Address*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.address}
              onChange={(e) => handleFieldChange("address", e.target.value)}
              error={!!errors.address}
              helperText={errors.address}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Email*
            </Typography>
            <TextField
              type="email"
              fullWidth
              variant="filled"
              value={userDetails.email}
              onChange={(e) => handleFieldChange("email", e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Phone Number*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.tel}
              onChange={(e) => handleFieldChange("tel", e.target.value)}
              error={!!errors.tel}
              helperText={errors.tel}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              No of users*
            </Typography>
            <TextField
              type="number"
              fullWidth
              variant="filled"
              value={userDetails.noOfUsers}
              onChange={(e) => handleFieldChange("noOfUsers", e.target.value)}
              error={!!errors.noOfUsers}
              helperText={errors.noOfUsers}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Date*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={userDetails.Date ? userDetails.Date : ""}
                onChange={(e) => handleFieldChange("Date", e.target.value)}
                name="Date"
                error={!!errors.Date}>
                <MenuItem value="Sunday">Sunday</MenuItem>
                <MenuItem value="Monday">Monday</MenuItem>
                <MenuItem value="Tuesday">Tuesday</MenuItem>
                <MenuItem value="Wednesday">Wednesday</MenuItem>
                <MenuItem value="Thursday">Thursday</MenuItem>
                <MenuItem value="Friday">Friday</MenuItem>
                <MenuItem value="Saturday">Saturday</MenuItem>
              </Select>
              {errors.Date && (
                <Typography variant="caption" color="error">
                  {errors.Date}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Time*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={userDetails.time}
                onChange={(e) => handleFieldChange("time", e.target.value)}
                name="time"
                error={!!errors.time}>
                {Array.from({ length: 24 }, (_, i) => (
                  <MenuItem
                    key={i}
                    value={`${String(i).padStart(2, "0")}:00:00`}>
                    {`${String(i).padStart(2, "0")}:00`}
                  </MenuItem>
                ))}
              </Select>
              {errors.time && (
                <Typography variant="caption" color="error">
                  {errors.time}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Longitude*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.longitude}
              onChange={(e) => handleFieldChange("longitude", e.target.value)}
              error={!!errors.longitude}
              helperText={errors.longitude}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Latitude*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.latitude}
              onChange={(e) => handleFieldChange("latitude", e.target.value)}
              error={!!errors.latitude}
              helperText={errors.latitude}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Paster*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.paster}
              onChange={(e) => handleFieldChange("paster", e.target.value)}
              error={!!errors.paster}
              helperText={errors.paster}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Programs*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.programs}
              onChange={(e) => handleFieldChange("programs", e.target.value)}
              error={!!errors.programs}
              helperText={errors.programs}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Open hours*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={userDetails.openHours}
              onChange={(e) => handleFieldChange("openHours", e.target.value)}
              error={!!errors.openHours}
              helperText={errors.openHours}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Image</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                accept="image/*"
                onChange={(e) => handleFieldChange("image", e.target.files[0])}
                name="image"
                error={!!errors.image}
                helperText={errors.image}
              />
            </Box>
          </Grid>
          {currentImage != "" && (
            <Grid item xs={12} md={6}>
              <Box>
                <div style={{ fontWeight: "bold" }}>Current Image</div>
                <img
                  src={currentImage}
                  alt="image"
                  style={{
                    marginTop: "5px",
                    width: "100%",
                  }}
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={() => updateWorshipCenterHandler(userDetails)}>
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}>
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}

export default EditEvent;
