import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Alert as MuiAlert,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";

const ViewTourCost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [initialValues, setInitialValues] = useState({
    tourPackage: "",
    tourType: "",
    tourCost: "",
  });

  useEffect(() => {
    fetchTourDetails();
  }, [id]);

  const fetchTourDetails = async () => {
    try {
      const response = await fetch(
        `https://jcgnapi.hasthiya.org/tourCost/findTourCost/${id}`
      );
      const responseData = await response.json();
      if (responseData.status) {
        const { tourPackage, tourType, tourCost } = responseData.result;
        setInitialValues({ tourPackage, tourType, tourCost });
      } else {
        setAlertSeverity("error");
        setAlertMessage(responseData.message || "Failed to fetch details");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error fetching tour cost details:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to fetch tour details");
      setOpenSnackbar(true);
    }
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://jcgnapi.hasthiya.org/tourCost/updateTourCost/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      const responseData = await response.json();
      if (responseData.status) {
        setAlertSeverity("success");
        setAlertMessage("Tour Cost Updated Successfully");
        setTimeout(() => {
          navigate("/tourCost");
        }, 2000);
      } else {
        setAlertSeverity("error");
        setAlertMessage(responseData.message || "Failed to update tour cost");
      }
    } catch (error) {
      console.error("Error updating tour cost:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to update tour cost");
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  const validationSchema = yup.object().shape({
    tourPackage: yup.string().required("Tour package is required"),
    tourType: yup
      .string()
      .oneOf(["christian trip", "regular trip", "other"], "Invalid tour type")
      .required("Tour type is required"),
    tourCost: yup
      .number()
      .typeError("Tour cost must be a number")
      .required("Tour cost is required"),
  });

  return (
    <Box
      m="20px"
      height="70vh"
      overflow="auto"
      paddingRight="20px"
      position="relative"
    >
      <Header title={`Edit Tour Cost ID: ${id}`} subtitle="" />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          sx={{ color: "#fff" }}
        >
          <AlertTitle>
            {alertSeverity === "success" ? "Success" : "Error"}
          </AlertTitle>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <Typography fontWeight="bold" fontSize="16px">
                Tour Package*
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tourPackage}
                name="tourPackage"
                error={!!touched.tourPackage && !!errors.tourPackage}
                helperText={touched.tourPackage && errors.tourPackage}
              />
              <Typography fontWeight="bold" fontSize="16px">
                Tour Type*
              </Typography>
              <FormControl
                fullWidth
                variant="filled"
                error={!!touched.tourType && !!errors.tourType}
              >
                <InputLabel>Tour Type</InputLabel>
                <Select
                  name="tourType"
                  value={values.tourType}
                  onChange={(e) => setFieldValue("tourType", e.target.value)}
                  onBlur={handleBlur}
                >
                  <MenuItem value="christian trip">Christian Trip</MenuItem>
                  <MenuItem value="regular trip">Regular Trip</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                {touched.tourType && errors.tourType && (
                  <FormHelperText>{errors.tourType}</FormHelperText>
                )}
              </FormControl>

              <Typography fontWeight="bold" fontSize="16px">
                Tour Cost*
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tourCost}
                name="tourCost"
                error={!!touched.tourCost && !!errors.tourCost}
                helperText={touched.tourCost && errors.tourCost}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  marginRight: 2,
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {loading ? "Updating..." : "Update"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ViewTourCost;
