import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Snackbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { mockDataJcgn } from "../../data/mockData";
import { tokens } from "../../theme";
import { useEffect } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import Swal from "sweetalert2";
import {
  getAllWorshipCenters,
  updateWorshipCenter,
  deleteWorshipCenter,
} from "./../../ApiConfig.jsx";

const JCGN = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const fetchData = async () => {
    const WorshipCentersData = await getAllWorshipCenters();

    setData(WorshipCentersData);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleStatusChange = async (id, newStatus, params) => {
    console.log("🚀 ~ handleStatusChange ~ params:", params)
    try {
      const body = {
        church: params.church,
        address: params.address,
        email: params.email,
        tel: params.tel,
        noOfUsers: params.noOfUsers,
        Date: params.Date,
        time: params.time,
        longtitude: params.longtitude,
        latitude: params.latitude,
        paster: params.paster,
        programs: params.programs.join(", "),
        openHours: params.openHours,
        status: newStatus,
      };
      const respondData = await updateWorshipCenter(id, body);
      if (respondData.status) {
        const updatedData = data.map((item) => {
          if (item.id === id) {
            return { ...item, status: newStatus };
          }
          return item;
        });
        setData(updatedData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleViewClick = (id, role) => {
    navigate(`/worship-centers/view/${id}`);
  };

  const handleEditClick = (id, role) => {
    navigate(`/worship-centers/edit/${id}`);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "id", headerName: "S.NO" },
    { field: "church", headerName: "Church", flex: 1 },
    { field: "address", headerName: "Address", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "tel", headerName: "Tel", flex: 1 },
    { field: "createdAt", headerName: "Added Date", flex: 1 },
    { field: "noOfUsers", headerName: "No Of Users", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Select
          value={params.row.status}
          onChange={(event) =>
            handleStatusChange(params.row.id, event.target.value, params.row)
          }
          style={{ width: "100px" }}
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </Select>
      ),
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <IconButton
              onClick={() => handleViewClick(params.row.id, params.row.role)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEditClick(params.row.id, params.row.role)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this worship center!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteResponse = await deleteWorshipCenter(id);
          if (!deleteResponse.status) {
            throw new Error("Failed to delete worship center");
          }
          fetchData();
          setAlertSeverity("success");
          setAlertMessage("Worship center deleted successfully");
        } catch (error) {
          console.log(error);
          setAlertSeverity("error");
          setAlertMessage(`Failed to delete worship center: ${error.message}`);
        } finally {
          setOpenSnackbar(true);
        }
      }
    });
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Header title="Worship Centers Management" subtitle="" />
        <Box>
          <Link
            to={"create-new-worship-center"}
            style={{ marginRight: "10px" }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
            >
              Add New Worship Center
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default JCGN;
