import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const TourCost = () => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const fetchTourCosts = async () => {
    try {
      const response = await fetch(
        "https://jcgnapi.hasthiya.org/tourCost/getAllTourCost"
      );
      const responseData = await response.json();
      if (responseData.status) {
        const modifiedData = responseData.result.map((item) => ({
          ...item,
          createdAt: item.createdAt.split("T")[0],
        }));

        // Sort in descending order by ID
        modifiedData.sort((a, b) => b.id - a.id);

        setData(modifiedData);
      } else {
        console.error("Failed to fetch tour costs:", responseData.message);
      }
    } catch (error) {
      console.error("Error fetching tour costs:", error);
    }
  };

  useEffect(() => {
    fetchTourCosts();
  }, []);

  const exportToPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        ["Tour ID", "Tour Package", "Tour Type", "Tour Cost", "Created At"],
      ],
      body: data.map(({ id, tourPackage, tourType, tourCost, createdAt }) => [
        id,
        tourPackage,
        tourType,
        tourCost,
        createdAt,
      ]),
    });
    doc.save("tour_cost_data.pdf");
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this tour cost!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://jcgnapi.hasthiya.org/tourCost/deleteTourCost/${id}`, {
          method: "DELETE",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to delete tour cost");
            }

            setData(data.filter((item) => item.id !== id));
            Swal.fire("Deleted!", "The tour cost has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting tour cost:", error);
            Swal.fire(
              "Error!",
              "Failed to delete tour cost. Please try again later.",
              "error"
            );
          });
      }
    });
  };

  const columns = [
    { field: "id", headerName: "Tour ID", flex: 0.5 },
    { field: "tourPackage", headerName: "Tour Package", flex: 1.5 },
    { field: "tourType", headerName: "Tour Type", flex: 1 },
    { field: "tourCost", headerName: "Tour Cost (USD)", flex: 1 },
    { field: "createdAt", headerName: "Created At (YYYY/MM/DD)", flex: 1 },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Edit">
            <Link to={`/tourCost/edit/${params.row.id}`}>
              <IconButton>
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="View">
            <Link to={`/tourCost/view/${params.row.id}`}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="-10px"
      >
        <Header title="Tour Cost Management" subtitle="Managing tour costs" />
        <Box>
          <Link to={"/tourCost/new"} style={{ marginRight: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
            >
              Add a Tour Cost
            </Button>
          </Link>
        </Box>
      </Box>
      <Box
        m="10px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Button
          variant="contained"
          onClick={exportToPdf}
          sx={{
            backgroundColor: "#4caf50",
            color: "white",
            fontSize: "10px",
            "&:hover": {
              backgroundColor: "#388e3c",
            },
          }}
        >
          Export as PDF
        </Button>
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default TourCost;
