import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Tooltip,
  useTheme,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const Feedbacks = () => {
  const [data, setData] = useState([]);
  const [feedbackType, setFeedbackType] = useState("product"); // Track the selected feedback type
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  // Fetch feedbacks based on the selected type
  const fetchFeedbacks = async (type) => {
    try {
      const url =
        type === "product"
          ? "https://jcgnapi.hasthiya.org/productFeedBack/getAllProductFeedbacks"
          : "https://jcgnapi.hasthiya.org/api/feedback/getAllFeedbacks";

      const response = await fetch(url);
      const result = await response.json();

      if (response.ok && result.status) {
        // Adjust data structure to unify the feedback format
        const unifiedData = result.result.map((item) => ({
          id: item.id,
          description: item.description,
          createdAt: item.createdAt,
          // Use blogId or productId based on feedback type
          identifier: type === "product" ? item.productId : item.blogId,
          type: type, // Store feedback type for easier identification
        }));

        // Sort by 'id' in descending order (biggest to smallest)
        const sortedData = unifiedData.sort((a, b) => b.id - a.id);

        // Update state with sorted data
        setData(sortedData);
      } else {
        setAlertSeverity("error");
        setAlertMessage("Failed to fetch feedbacks.");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error fetching feedback:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to fetch feedbacks: " + error.message);
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchFeedbacks(feedbackType); // Fetch feedbacks based on the current type
  }, [feedbackType]);

  const handleViewClick = (id) => {
    const path =
      feedbackType === "product"
        ? `/feedback/package/${id}`
        : `/feedback/blog/${id}`;
    navigate(path);
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this feedback!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url =
            feedbackType === "product"
              ? `https://jcgnapi.hasthiya.org/productFeedBack/deleteFeedbackByID/${id}`
              : `https://jcgnapi.hasthiya.org/api/feedback/deleteFeedbackByID/${id}`;

          const response = await fetch(url, { method: "DELETE" });

          if (!response.ok) throw new Error("Failed to delete feedback");

          // Remove the deleted feedback from the state
          setData((prevData) => prevData.filter((item) => item.id !== id));

          // Show success alert
          Swal.fire("Deleted!", "The feedback has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting feedback:", error);
          Swal.fire(
            "Error!",
            "Failed to delete feedback. Please try again later.",
            "error"
          );
        }
      }
    });
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "description", headerName: "Description", flex: 2 },
    {
      field: "createdAt",
      headerName: "Date Submitted",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "identifier",
      headerName: feedbackType === "product" ? "Product ID" : "Blog ID",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <IconButton onClick={() => handleViewClick(params.row.id)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Header
          title="Feedbacks"
          subtitle="Showing All The Feedbacks Received"
        />

        {/* Buttons to select feedback type */}
        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#6870fa",
              color: "white",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#3e4396",
              },
            }}
            onClick={() => setFeedbackType("product")}
          >
            Product Feedback
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#6870fa",
              color: "white",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#3e4396",
              },
              marginLeft: "10px",
            }}
            onClick={() => setFeedbackType("blog")}
          >
            Blog Feedback
          </Button>
        </Box>
      </Box>

      <Box
        m="-20px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default Feedbacks;
