import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import axios from "axios";
import { getWorshipCenter } from "./../../ApiConfig.jsx";


function ViewWorship() {
  const { id } = useParams();

  const [videoDetails, setVideoDetails] = useState({});
     const [currentImage, setCurrentImage] = useState("");
  const fetchWorshipCenter  = async (id) => {
    try {
      const data = await getWorshipCenter(id);
      if (data.status) {
        console.log(data.result);
        setVideoDetails({
          Name: data.result.church,
          Telephone: data.result.tel,
          Email: data.result.email,
          Address: data.result.address,
          "No of users": data.result.noOfUsers,
          Date: data.result.Date,
          time: data.result.time,
          longitude: data.result.longtitude,
          latitude: data.result.latitude,
          paster: data.result.paster,
          programs: data.result.programs.join(", "),
          openHours: data.result.openHours,
        });
       setCurrentImage(data.result.imageURL);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWorshipCenter(id);
  }, [id]);
  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Worship Center ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(videoDetails).map(([field, value]) => (
            <React.Fragment key={field}>
              <Grid item xs={1.8}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  {field}:
                </Typography>
              </Grid>
              <Grid item xs={10.2}>
                <Typography>{value}</Typography>
              </Grid>
            </React.Fragment>
          ))}
          {currentImage && (
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}>
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Image:
                  </Typography>
                </Grid>
                <img
                  style={{ maxWidth: "400px", maxHeight: "400px" }}
                  src={currentImage}
                  alt=""
                />
              </div>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewWorship;
