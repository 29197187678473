import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function ViewFeedback() {
  const { id } = useParams();
  const [feedbackDetails, setFeedbackDetails] = useState({});

  // Fetch feedback details by product ID
  const getFeedbackByID = async (id) => {
    try {
      const response = await fetch(
        `https://jcgnapi.hasthiya.org/productFeedBack/getFeedbackById/${id}`
      );
      const result = await response.json();

      if (response.ok && result.status) {
        setFeedbackDetails(result.result);
      } else {
        console.error("Failed to fetch feedback:", result.message);
      }
    } catch (error) {
      console.error("Error fetching feedback:", error);
    }
  };

  useEffect(() => {
    getFeedbackByID(id);
  }, [id]);

  return (
    <Box m="20px" height="70vh" overflow="auto">
      <Header title={`View Feedback by Product ID: ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(feedbackDetails).map(([key, value]) => (
            <React.Fragment key={key}>
              <Grid item xs={2}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  {key.charAt(0).toUpperCase() +
                    key.slice(1).replace(/([A-Z])/g, " $1")}{" "}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h5" component="span">
                  :
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>
                  {key === "createdAt" || key === "updatedAt"
                    ? new Date(value).toLocaleDateString() // Format the date
                    : value}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewFeedback;
