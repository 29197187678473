import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function ViewInvoice() {
  const { id } = useParams();
  const [invoiceDetails, setInvoiceDetails] = useState({
    id: "",
    clientName: "",
    email: "",
    invoicenumber: "",
    address: "",
    organizationName: "",
    date: "",
    totalPrice: "",
    phoneNumber: "",
    createdAt: "",
    updatedAt: "",
  });

  useEffect(() => {
    fetchInvoiceDetails();
  }, [id]);

  const fetchInvoiceDetails = async () => {
    try {
      const response = await fetch(
        `https://jcgnapi.hasthiya.org/invoiceform/getInvoiceformById/${id}`
      );
      const responseData = await response.json();
      if (responseData.status) {
        const modifiedData = {
          ...responseData.result,
          date: responseData.result.date.split("T")[0],
          createdAt: responseData.result.createdAt.split("T")[0],
          updatedAt: responseData.result.updatedAt.split("T")[0],
        };
        setInvoiceDetails(modifiedData);
      } else {
        console.error("Failed to fetch invoice details:", responseData.message);
      }
    } catch (error) {
      console.error("Error fetching invoice details:", error);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Invoice ID: ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(invoiceDetails).map(([field, value]) => (
            <React.Fragment key={field}>
              <Grid item xs={2.5}>
                <Typography variant="h6" component="span" fontWeight="bold">
                  {capitalizeFirstLetter(field)}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h6" component="span" fontWeight="bold">
                  :
                </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <Typography>{value || "N/A"}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewInvoice;
