import GetAppIcon from "@mui/icons-material/GetApp";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function ViewPrescription() {
  const { id } = useParams();
  const [prescriptionDetails, setPrescriptionDetails] = useState({
    id: "",
    patientName: "",
    age: "",
    address: "",
    phoneNumber: "",
    email: "",
    drugCategory: "",
    dose: "",
    days: "",
    perDayDose: "",
    docname: "",
    phamacyname: "",
    createdAt: "",
    updatedAt: "",
  });

  useEffect(() => {
    fetchPrescriptionDetails();
  }, [id]);

  const fetchPrescriptionDetails = async () => {
    try {
      const response = await fetch(
        `https://jcgnapi.hasthiya.org/precriptionform/getprecriptionformById/${id}`
      );
      const responseData = await response.json();
      if (responseData.status) {
        const modifiedData = {
          ...responseData.result,
          createdAt: responseData.result.createdAt.split("T")[0],
          updatedAt: responseData.result.updatedAt.split("T")[0],
        };
        setPrescriptionDetails(modifiedData);
      } else {
        console.error(
          "Failed to fetch prescription details:",
          responseData.message
        );
      }
    } catch (error) {
      console.error("Error fetching prescription details:", error);
    }
  };

  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatFieldName = (field) => {
    // Special cases for medication fields
    if (field.match(/medicationname\d/)) {
      const num = field.match(/\d/)[0];
      return `Medication ${num} Name`;
    }
    if (field.match(/purpose\d/)) {
      const num = field.match(/\d/)[0];
      return `Medication ${num} Purpose`;
    }
    if (field.match(/frequency\d/)) {
      const num = field.match(/\d/)[0];
      return `Medication ${num} Frequency`;
    }
    if (field.match(/dosage\d/)) {
      const num = field.match(/\d/)[0];
      return `Medication ${num} Dosage`;
    }
    if (field.match(/pharmacy\d/)) {
      const num = field.match(/\d/)[0];
      return `Medication ${num} Pharmacy`;
    }
    if (field.match(/route\d/)) {
      const num = field.match(/\d/)[0];
      return `Medication ${num} Route`;
    }

    // General field name mappings
    const fieldMappings = {
      id: "Prescription ID",
      patientName: "Patient Name",
      phoneNumber: "Phone Number",
      email: "Email Address",
      drugCategory: "Drug Category",
      perDayDose: "Doses Per Day",
      docname: "Doctor Name",
      phamacyname: "Pharmacy Name",
      physname: "Physician Name",
      physnumber: "Physician Number",
      physemail: "Physician Email",
      physsign: "Physician Signature",
      physdate: "Prescription Date",
      hospitalname: "Hospital Name",
      physaddress: "Physician Address",
      createdAt: "Created Date",
      updatedAt: "Last Updated",
      age: "Age",
      address: "Address",
      dose: "Dose",
      days: "Days",
      condition: "Condition",
    };

    // Get the mapped name or split camelCase
    const fieldName =
      fieldMappings[field] || field.split(/(?=[A-Z])/).join(" ");

    // Capitalize each word
    return capitalizeWords(fieldName);
  };

  const renderFieldValue = (field, value) => {
    if (field === "physsign" && value) {
      return (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<GetAppIcon />}
          href={value}
          target="_blank"
          download
        >
          Download PDF
        </Button>
      );
    }
    return <Typography>{value || "N/A"}</Typography>;
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Prescription ID: ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(prescriptionDetails).map(([field, value]) => (
            <React.Fragment key={field}>
              <Grid item xs={2.5}>
                <Typography variant="h6" component="span" fontWeight="bold">
                  {formatFieldName(field)}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h6" component="span" fontWeight="bold">
                  :
                </Typography>
              </Grid>
              <Grid item xs={8.5}>
                {renderFieldValue(field, value)}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewPrescription;
