import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const PrescriptionManagement = () => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const fetchPrescriptions = async () => {
    try {
      const response = await fetch(
        "https://jcgnapi.hasthiya.org/precriptionform/getAllprecriptionforms"
      );
      const responseData = await response.json();
      if (responseData.status) {
        const modifiedData = responseData.result.map((item) => ({
          ...item,
          createdAt: item.createdAt.split("T")[0], // Extract only the date part
        }));

        modifiedData.sort((a, b) => b.id - a.id);
        setData(modifiedData);
      } else {
        console.error("Failed to fetch prescriptions:", responseData.message);
      }
    } catch (error) {
      console.error("Error fetching prescriptions:", error);
    }
  };

  useEffect(() => {
    fetchPrescriptions();
  }, []);

  const exportToPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "ID",
          "Patient Name",
          "Age",
          "Address",
          "Phone Number",
          "Drug Category",
          "Dose",
          "Days",
          "Per Day Dose",
          "Doctor Name",
          "Pharmacy Name",
          "Date",
        ],
      ],
      body: data.map(
        ({
          id,
          patientName,
          age,
          address,
          phoneNumber,
          drugCategory,
          dose,
          days,
          perDayDose,
          docname,
          phamacyname,
          createdAt,
        }) => [
          id,
          patientName,
          age,
          address,
          phoneNumber,
          drugCategory,
          dose,
          days,
          perDayDose,
          docname,
          phamacyname,
          createdAt,
        ]
      ),
    });
    doc.save("prescriptions_data.pdf");
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this prescription!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          `https://jcgnapi.hasthiya.org/precriptionform/deleteprecriptionformByID/${id}`,
          {
            method: "DELETE",
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to delete prescription");
            }
            setData(data.filter((item) => item.id !== id));
            Swal.fire(
              "Deleted!",
              "The prescription has been deleted.",
              "success"
            );
          })
          .catch((error) => {
            console.error("Error deleting prescription:", error);
            Swal.fire(
              "Error!",
              "Failed to delete prescription. Please try again later.",
              "error"
            );
          });
      }
    });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "patientName", headerName: "Patient Name", flex: 1 },
    { field: "age", headerName: "Age", width: 100 },
    // { field: "address", headerName: "Address", flex: 1 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    { field: "drugCategory", headerName: "Drug Category", flex: 1 },
    // { field: "dose", headerName: "Dose", flex: 1 },
    // { field: "days", headerName: "Days", flex: 1 },
    // { field: "perDayDose", headerName: "Per Day Dose", flex: 1 },
    // { field: "docname", headerName: "Doctor Name", flex: 1 },
    // { field: "phamacyname", headerName: "Pharmacy Name", flex: 1 },
    { field: "createdAt", headerName: "Date (YYYY/MM/DD)", width: 150 },
    {
      field: "Actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <Link to={`/prescription/view/${params.row.id}`}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="-10px"
      >
        <Header
          title="Prescription Management"
          subtitle="Managing prescriptions"
        />
      </Box>
      <Box
        m="10px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Button
          variant="contained"
          onClick={exportToPdf}
          sx={{
            backgroundColor: "#4caf50",
            color: "white",
            fontSize: "10px",
            "&:hover": {
              backgroundColor: "#388e3c",
            },
          }}
        >
          Export as PDF
        </Button>
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default PrescriptionManagement;
