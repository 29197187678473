import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function ViewTourCost() {
  const { id } = useParams();
  const [tourDetails, setTourDetails] = useState({
    id: "",
    tourPackage: "",
    tourType: "",
    tourCost: "",
    createdAt: "",
    updatedAt: "",
  });

  useEffect(() => {
    fetchTourDetails();
  }, [id]);

  const fetchTourDetails = async () => {
    try {
      const response = await fetch(
        `https://jcgnapi.hasthiya.org/tourCost/findTourCost/${id}`
      );
      const responseData = await response.json();

      if (responseData.status) {
        const { result } = responseData;
        const modifiedData = {
          ...result,
          createdAt: formatDate(result.createdAt), // Format createdAt
          updatedAt: formatDate(result.updatedAt), // Format updatedAt
        };
        setTourDetails(modifiedData);
      } else {
        console.error(
          "Failed to fetch tour cost details:",
          responseData.message
        );
      }
    } catch (error) {
      console.error("Error fetching tour cost details:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header title={`View Tour Cost ID: ${id}`} subtitle="" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(tourDetails).map(([field, value]) => (
            <React.Fragment key={field}>
              <Grid item xs={1.8}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  {capitalizeFirstLetter(field)}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  :
                </Typography>
              </Grid>
              <Grid item xs={9.2}>
                <Typography>{value}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewTourCost;
