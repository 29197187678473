import { CssBaseline, ThemeProvider } from "@mui/material";
import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoutes from "./Utils/PrivateRoutes.jsx";
import AddNewAudio from "./scenes/AudioManagement/AddNewAudio";
import EditAudio from "./scenes/AudioManagement/EditAudio";
import ViewAudio from "./scenes/AudioManagement/ViewAudio";
import AudioManagement from "./scenes/AudioManagement/audioManagement";
import Feedbacks from "./scenes/Feedbacks";
import ViewFeedbackBlogs from "./scenes/Feedbacks/ViewFeedbackBlogs.jsx";
import ViewFeedbackProducts from "./scenes/Feedbacks/ViewFeedbackProducts.jsx";
import CreateNewWorship from "./scenes/JCGN/CreateNewWorship";
import EditWorshipCenter from "./scenes/JCGN/EditWorshipCenter.jsx";
import ViewWorship from "./scenes/JCGN/ViewWorship.jsx";
import JCGN from "./scenes/JCGN/jcgn";
import AddMaladiesAndConditions from "./scenes/MaladiesAndConditions/AddMaladiesAndConditions.jsx";
import EditMaladiesAndConditions from "./scenes/MaladiesAndConditions/EditMaladiesAndConditions.jsx";
import MaladiesAndConditionsManagement from "./scenes/MaladiesAndConditions/MaladiesAndConditionsManagement.jsx";
import ViewMaladiesAndConditions from "./scenes/MaladiesAndConditions/ViewMaladiesAndConditions.jsx";
import MembershipCard from "./scenes/MembershipCard";
import EditMembershipCard from "./scenes/MembershipCard/EditMembershipCard.jsx";
import ViewMembershipCard from "./scenes/MembershipCard/ViewMembershipCard.jsx";
import CreateNewMembership from "./scenes/MembershipCard/createNewMembership";
import CreateNewQuestion from "./scenes/VoteManagement/CreateNewQuestion.jsx";
import EditQuestion from "./scenes/VoteManagement/EditQuestion.jsx";
import ViewQuestion from "./scenes/VoteManagement/ViewQuestion.jsx";
import Votes from "./scenes/VoteManagement/Votes.jsx";
import ActivityReport from "./scenes/activityReport";
import EditActivityReport from "./scenes/activityReport/EditActivityReport.jsx";
import ViewActivityReport from "./scenes/activityReport/ViewActivityReport.jsx";
import CreateNewAdminService from "./scenes/adminServiceManagement/CreateNewAdminService";
import EditWorshipService from "./scenes/adminServiceManagement/EditWorshipService.jsx";
import ViewWorshipService from "./scenes/adminServiceManagement/ViewWorshipService.jsx";
import AdminServiceManagement from "./scenes/adminServiceManagement/adminServiceManagement";
import ViewAfyaBora from "./scenes/afyaBora/ViewAfyaBora.jsx";
import AfyaBora from "./scenes/afyaBora/index.jsx";
import AppointmentLogs from "./scenes/appointmentLogs";
import ViewAppointment from "./scenes/appointmentLogs/ViewAppointment";
import CreateNewArtist from "./scenes/artistManagement/CreateNewArtist";
import EditArtist from "./scenes/artistManagement/EditArtist.jsx";
import ViewArtist from "./scenes/artistManagement/ViewArtist.jsx";
import ArtistManagement from "./scenes/artistManagement/artistManagement";
import CreateNewBlog from "./scenes/blogs/CreateNewBlog";
import EditBlog from "./scenes/blogs/EditBlog.jsx";
import ViewBlog from "./scenes/blogs/ViewBlog.jsx";
import Blogs from "./scenes/blogs/blogs";
import Careers from "./scenes/careers";
import CreateNewCareer from "./scenes/careers/CreateNewCareer";
import EditCareer from "./scenes/careers/EditCareer";
import ViewCareer from "./scenes/careers/ViewCareer";
import CareerRepliers from "./scenes/careers/repliers/Repliers";
import ViewReplyCareer from "./scenes/careers/repliers/ViewReplyCareer.jsx";
import ViewReplyCareerUser from "./scenes/careers/repliers/ViewReplyCareerUser.jsx";
import ViewContact from "./scenes/contactUs/ViewContact.jsx";
import Contactus from "./scenes/contactUs/index.jsx";
import ContentManagement from "./scenes/contentManagement";
import CreateNewContent from "./scenes/contentManagement/CreateNewContent";
import EditContent from "./scenes/contentManagement/EditContent.jsx";
import ViewContent from "./scenes/contentManagement/ViewContent";
import AddModuleDetails from "./scenes/courseModule/AddModuleDetailsToModule.jsx";
import AddNewModule from "./scenes/courseModule/AddNewModule.jsx";
import EditModule from "./scenes/courseModule/ModuleEdit.jsx";
import ViewModuleByID from "./scenes/courseModule/ViewModuleByID.jsx";
import Module from "./scenes/courseModule/index.jsx";
import CreateNewCourse from "./scenes/courses/CreateNewCourse.jsx";
import EditCourse from "./scenes/courses/EditCourse.jsx";
import ViewCourse from "./scenes/courses/ViewCourse.jsx";
import CoursesManagement from "./scenes/courses/index.jsx";
import AddCoveredCareServices from "./scenes/coveredCareServices/AddCoveredCareServices.jsx";
import CoveredCareServicesManagement from "./scenes/coveredCareServices/CoveredCareServicesManagement.jsx";
import EditCoveredCareServices from "./scenes/coveredCareServices/EditCoveredCareServices.jsx";
import ViewCoveredCareServices from "./scenes/coveredCareServices/ViewCoveredCareServices.jsx";
import Dashboard from "./scenes/dashboard";
import Dedication from "./scenes/dedication";
import ViewDedication from "./scenes/dedication/ViewDedication.jsx";
import EventManagement from "./scenes/eventManagement";
import CreateNewEvent from "./scenes/eventManagement/CreateNewEvent";
import EditEvents from "./scenes/eventManagement/EditEvent.jsx";
import ViewEvents from "./scenes/eventManagement/ViewEvents";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
import AddHospitalsAndHealthCenters from "./scenes/hospitalsAndHealthCenters/AddHospitalsAndHealthCenters.jsx";
import EditHospitalsAndHealthCenters from "./scenes/hospitalsAndHealthCenters/EditHospitalsAndHealthCenters.jsx";
import HospitalsAndHealthCentersManagement from "./scenes/hospitalsAndHealthCenters/HospitalsAndHealthCentersManagement.jsx";
import ViewHospitalsAndHealthCenters from "./scenes/hospitalsAndHealthCenters/ViewHospitalsAndHealthCenters.jsx";
import Invoices from "./scenes/invoices";
import InvoicesByID from "./scenes/invoices/ViewInvoice.jsx";
import Login from "./scenes/login";
import FormUserView from "./scenes/newReqst/FormUserView";
import FormsUser from "./scenes/newReqst/FormsUser";
import NewServiceRequest from "./scenes/newReqst/NewServiceRequest";
import NewServiceView from "./scenes/newReqst/NewServiceView";
import News from "./scenes/news";
import AddNews from "./scenes/news/AddNews";
import EditNews from "./scenes/news/EditNews";
import ViewNews from "./scenes/news/ViewNews";
import PaymentLogs from "./scenes/paymentLogs";
import ViewPayment from "./scenes/paymentLogs/ViewPayment.jsx";
import ViewPaymentByID from "./scenes/paymentLogs/ViewPaymentbyId.jsx";
import PrescriptionView from "./scenes/prescription/ViewPrescription.jsx";
import Prescription from "./scenes/prescription/index.jsx";
import Profile from "./scenes/profile";
import ChangePw from "./scenes/profile/ChangePw";
import EditProfile from "./scenes/profile/EditProfile";
import SermonsManagement from "./scenes/sermonsManagement";
import CreateNewSermons from "./scenes/sermonsManagement/CreateNewSermons";
import EditSermons from "./scenes/sermonsManagement/EditSermons.jsx";
import ViewSermons from "./scenes/sermonsManagement/ViewSermons";
import SubscriptionPackage from "./scenes/subscriptionPackage";
import EditSubscription from "./scenes/subscriptionPackage/EditSubscription";
import NewSubscripion from "./scenes/subscriptionPackage/NewSubscripion";
import ViewSubscription from "./scenes/subscriptionPackage/ViewSubscription";
import EditSubUser from "./scenes/subscriptionPackage/subscribedUsers/EditSubUser";
import SubscribedUsers from "./scenes/subscriptionPackage/subscribedUsers/Index";
import ViewSubUser from "./scenes/subscriptionPackage/subscribedUsers/ViewSubUser";
import Team from "./scenes/team";
import AddNewOrganization from "./scenes/team/AddNewOrganization";
import CreateNewUser from "./scenes/team/CreateNewUser";
import EditOrganization from "./scenes/team/EditOrganization";
import EditUser from "./scenes/team/EditUser";
import ViewAfya from "./scenes/team/ViewAfya.jsx";
import ViewChurchMember from "./scenes/team/ViewChurchMember";
import ViewOrganization from "./scenes/team/ViewOrganization";
import ViewProfessor from "./scenes/team/ViewProfessor.jsx";
import ViewSeller from "./scenes/team/ViewSeller.jsx";
import ViewStudent from "./scenes/team/ViewStudent.jsx";
import ViewUser from "./scenes/team/ViewUser";
import Template from "./scenes/template";
import EditTemplate from "./scenes/template/EditTemplate.jsx";
import ViewTemplate from "./scenes/template/ViewTemplate.jsx";
import CreateNewTemplate from "./scenes/template/createNewTemplate";
import AddTourCost from "./scenes/tourCost/AddTourCost.jsx";
import AddNewVideo from "./scenes/videoManagement/AddNewVideo";
import EditVideo from "./scenes/videoManagement/EditVideo";
import VideoManagement from "./scenes/videoManagement/VideoManagement";
import ViewVideo from "./scenes/videoManagement/ViewVideo";
import { ColorModeContext, useMode } from "./theme";

import AddDoctor from "./scenes/doctor/AddDoctor.jsx";
import DoctorManagement from "./scenes/doctor/DoctorManagement.jsx";
import EditDoctor from "./scenes/doctor/EditDoctor.jsx";
import ViewDoctor from "./scenes/doctor/ViewDoctor.jsx";

import AddPharmacy from "./scenes/pharmacy/AddPharmacy.jsx";
import EditPharmacy from "./scenes/pharmacy/EditPharmacy.jsx";
import PharmacyManagement from "./scenes/pharmacy/PharmacyManagement.jsx";
import ViewPharmacy from "./scenes/pharmacy/ViewPharmacy.jsx";
import ViewPreTour from "./scenes/tour/preRegister/ViewPreTour.jsx";
import Tour from "./scenes/tour/preRegister/index.jsx";
import ViewRegTour from "./scenes/tour/tourRegister/ViewRegisterTour.jsx";
import TourRegister from "./scenes/tour/tourRegister/index.jsx";

import BurialExpencesAdd from "./scenes/burialExpensesClaim/AddBurialExpences.jsx";
import AddFeeSchedulesForHospitalServices from "./scenes/feeSchedulesForHospitalServices/AddFeeSchedulesForHospitalServices.jsx";
import EditFeeSchedulesForHospitalServices from "./scenes/feeSchedulesForHospitalServices/EditFeeSchedulesForHospitalServices.jsx";
import FeeSchedulesForHospitalServicesManagement from "./scenes/feeSchedulesForHospitalServices/FeeSchedulesForHospitalServicesManagement.jsx";
import ViewFeeSchedulesForHospitalServices from "./scenes/feeSchedulesForHospitalServices/ViewFeeSchedulesForHospitalServices.jsx";
import AddSpecialistDoctor from "./scenes/specialistDoctor/AddSpecialistDoctor.jsx";
import EditSpecialistDoctor from "./scenes/specialistDoctor/EditSpecialistDoctor.jsx";
import SpecialistDoctorManagement from "./scenes/specialistDoctor/SpecialistDoctorManagement.jsx";
import ViewSpecialistDoctor from "./scenes/specialistDoctor/ViewSpecialistDoctor.jsx";

import AddAddFeeScheduleForMedication from "./scenes/feeScheduleForMedication/AddAddFeeScheduleForMedication.jsx";
import EditFeeScheduleForMedication from "./scenes/feeScheduleForMedication/EditFeeScheduleForMedication.jsx";
import FeeScheduleForMedicationManagement from "./scenes/feeScheduleForMedication/FeeScheduleForMedicationManagement.jsx";
import ViewFeeScheduleForMedication from "./scenes/feeScheduleForMedication/ViewFeeScheduleForMedication.jsx";

import AddCoveredMedicationsAndDrugs from "./scenes/coveredMedicationsAndDrugs/AddCoveredMedicationsAndDrugs.jsx";
import CoveredMedicationsAndDrugsManagement from "./scenes/coveredMedicationsAndDrugs/CoveredMedicationsAndDrugsManagement.jsx";
import EditCoveredMedicationsAndDrugs from "./scenes/coveredMedicationsAndDrugs/EditCoveredMedicationsAndDrugs.jsx";
import ViewCoveredMedicationsAndDrugs from "./scenes/coveredMedicationsAndDrugs/ViewCoveredMedicationsAndDrugs.jsx";

import AddFuneralsAndMortuariesHomes from "./scenes/funeralsAndMortuariesHomes/AddFuneralsAndMortuariesHomes.jsx";
import EditFuneralsAndMortuariesHomes from "./scenes/funeralsAndMortuariesHomes/EditFuneralsAndMortuariesHomes.jsx";
import FuneralsAndMortuariesHomesManagement from "./scenes/funeralsAndMortuariesHomes/FuneralsAndMortuariesHomesManagement";
import ViewFuneralsAndMortuariesHomes from "./scenes/funeralsAndMortuariesHomes/ViewFuneralsAndMortuariesHomes.jsx";

import BurialExpencesView from "./scenes/burialExpensesClaim/ViewBurialExpenses.jsx";

import BurialExpencesEdit from "./scenes/burialExpensesClaim/EditBurialExpences.jsx";
import BurialExpences from "./scenes/burialExpensesClaim/index.jsx";
import EditModuleDetailsById from "./scenes/courseModule/EditModuleDetailsById.jsx";
import AddModulesToCourse from "./scenes/courses/AddModulesToCourse.jsx";
import AddFeeSchedulesForFuneralHome from "./scenes/feeSchedulesForFuneralHome/AddFeeSchedulesForFuneralHome.jsx";
import EditFeeSchedulesForFuneralHome from "./scenes/feeSchedulesForFuneralHome/EditFeeSchedulesForFuneralHome.jsx";
import FeeSchedulesForFuneralHomeManagement from "./scenes/feeSchedulesForFuneralHome/FeeSchedulesForFuneralHomeManagement.jsx";
import ViewFeeSchedulesForFuneralHome from "./scenes/feeSchedulesForFuneralHome/ViewFeeSchedulesForFuneralHome.jsx";
import EditTourCost from "./scenes/tourCost/EditTourCost.jsx";
import ViewTourCost from "./scenes/tourCost/ViewTourCost.jsx";
import TourCost from "./scenes/tourCost/index.jsx";
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();

  // Check if the current route is the login page
  const isLoginPage = location.pathname === "/";
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {!isLoginPage && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {!isLoginPage && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/" element={<Login />} />

              <Route element={<PrivateRoutes />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/user" element={<Team />} />
                <Route
                  path="/subscription"
                  element={<SubscriptionPackage></SubscriptionPackage>}
                />
                <Route
                  path="/subscribedusers"
                  element={<SubscribedUsers></SubscribedUsers>}
                />
                <Route
                  path="/subscribedusers/viewsubscribeduser/:id"
                  element={<ViewSubUser></ViewSubUser>}
                />
                <Route
                  path="/subscribedusers/editsubscribeduser/:id"
                  element={<EditSubUser></EditSubUser>}
                />
                <Route
                  path="/dedication/view/:id"
                  element={<ViewDedication />}
                />

                <Route path="/dedication" element={<Dedication />} />

                <Route path="/contact/view/:id" element={<ViewContact />} />

                <Route path="/contactUs" element={<Contactus />} />
                <Route
                  path="/subscription/viewsubcription/:id"
                  element={<ViewSubscription></ViewSubscription>}
                />
                <Route
                  path="/subscription/newsubcription"
                  element={<NewSubscripion></NewSubscripion>}
                />
                <Route
                  path="/subscription/editsubcription/:id"
                  element={<EditSubscription></EditSubscription>}
                />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/invoice/view/:id" element={<InvoicesByID />} />

                <Route path="/tourCost" element={<TourCost />} />
                <Route path="/tourCost/new" element={<AddTourCost />} />
                <Route path="/tourCost/view/:id" element={<ViewTourCost />} />
                <Route path="/tourCost/edit/:id" element={<EditTourCost />} />

                <Route path="/prescription" element={<Prescription />} />
                <Route
                  path="/prescription/view/:id"
                  element={<PrescriptionView />}
                />

                <Route path="/appointment" element={<AppointmentLogs />} />

                <Route
                  path="/user/createuser"
                  element={<CreateNewUser></CreateNewUser>}
                />
                <Route
                  path="/user/addneworg"
                  element={<AddNewOrganization></AddNewOrganization>}
                />
                <Route
                  path="/appointment/view/:id"
                  element={<ViewAppointment />}
                />

                <Route path="/news" element={<News></News>}></Route>
                <Route
                  path="/news/newnews"
                  element={<AddNews></AddNews>}
                ></Route>

                <Route
                  path="/news/viewnews/:id"
                  element={<ViewNews></ViewNews>}
                ></Route>
                <Route
                  path="/news/editnews/:id"
                  element={<EditNews></EditNews>}
                ></Route>

                <Route
                  path="/activityreport"
                  element={<ActivityReport></ActivityReport>}
                ></Route>

                <Route
                  path="/activityreport/viewactivity/:id"
                  element={<ViewActivityReport></ViewActivityReport>}
                ></Route>
                <Route
                  path="/activityreport/editactivity/:id"
                  element={<EditActivityReport></EditActivityReport>}
                ></Route>

                <Route
                  path="/newserviceuser"
                  element={<NewServiceRequest></NewServiceRequest>}
                ></Route>
                <Route
                  path="/newserviceuser/view/:id"
                  element={<NewServiceView />}
                />
                <Route
                  path="/formsuser"
                  element={<FormsUser></FormsUser>}
                ></Route>
                <Route path="/formsuser/view/:id" element={<FormUserView />} />
                <Route path="/careers" element={<Careers></Careers>}></Route>
                <Route
                  path="/careers/newcareer"
                  element={<CreateNewCareer></CreateNewCareer>}
                ></Route>
                <Route
                  path="/careers/careerrepliers"
                  element={<CareerRepliers></CareerRepliers>}
                ></Route>
                <Route
                  path="/careers/repliers/controlcareer/:id"
                  element={<ViewReplyCareer></ViewReplyCareer>}
                ></Route>
                <Route
                  path="/careers/repliers/viewcareer/:id"
                  element={<ViewReplyCareerUser></ViewReplyCareerUser>}
                ></Route>
                <Route
                  path="/careers/viewcareer/:id"
                  element={<ViewCareer />}
                />
                <Route
                  path="/careers/editcareer/:id"
                  element={<EditCareer />}
                />

                <Route path="/tour" element={<Tour />} />

                <Route
                  path="/tour/preTour/view/:id"
                  element={<ViewPreTour />}
                />

                <Route path="/tour/register" element={<TourRegister />} />

                <Route
                  path="/tour/regTour/view/:id"
                  element={<ViewRegTour />}
                />

                <Route path="/event" element={<EventManagement />} />
                <Route path="/cms" element={<ContentManagement />} />
                <Route path="/sermons" element={<SermonsManagement />} />
                <Route path="/event/createevent" element={<CreateNewEvent />} />
                <Route
                  path="/cms/createcontent"
                  element={<CreateNewContent />}
                />
                <Route path="/cms/edit/:id" element={<EditContent />} />

                <Route path="/courses" element={<CoursesManagement />} />
                <Route path="/course/create" element={<CreateNewCourse />} />
                <Route path="/course/edit/:id" element={<EditCourse />} />
                <Route path="/course/view/:id" element={<ViewCourse />} />
                <Route
                  path="/course/addModules/:id"
                  element={<AddModulesToCourse />}
                />
                <Route path="/module" element={<Module />} />
                <Route path="/module/view/:id" element={<ViewModuleByID />} />

                <Route path="/module/create" element={<AddNewModule />} />
                <Route path="/module/edit/:id" element={<EditModule />} />
                <Route
                  path="/module/editDetails/:id"
                  element={<EditModuleDetailsById />}
                />
                <Route
                  path="/module/addDetails/:id"
                  element={<AddModuleDetails />}
                />

                <Route path="/event/edit/:id" element={<EditEvents />}></Route>

                <Route
                  path="/sermons/createsemons"
                  element={<CreateNewSermons />}
                />
                <Route
                  path="/paymentlogs"
                  element={<PaymentLogs></PaymentLogs>}
                ></Route>

                <Route
                  path="/paymentlogsbyID/view/:id"
                  element={<ViewPaymentByID></ViewPaymentByID>}
                ></Route>
                <Route
                  path="/paymentlogs/view/:id"
                  element={<ViewPayment></ViewPayment>}
                ></Route>
                <Route path="/profile" element={<Profile></Profile>}></Route>
                <Route
                  path="/profile/changepassword"
                  element={<ChangePw></ChangePw>}
                ></Route>
                <Route
                  path="/profile/editprofile"
                  element={<EditProfile></EditProfile>}
                ></Route>
                <Route
                  path="/user/viewuser/:id"
                  element={<ViewUser></ViewUser>}
                ></Route>
                <Route
                  path="/user/view/organization/:id"
                  element={<ViewOrganization></ViewOrganization>}
                ></Route>
                <Route
                  path="/user/view/ayfa/:id"
                  element={<ViewAfya></ViewAfya>}
                ></Route>

                <Route
                  path="/afyaborabookings/view/:id"
                  element={<ViewAfyaBora></ViewAfyaBora>}
                ></Route>
                <Route
                  path="/user/view/churchmember/:id"
                  element={<ViewChurchMember></ViewChurchMember>}
                ></Route>
                <Route
                  path="/user/view/professor/:id"
                  element={<ViewProfessor></ViewProfessor>}
                ></Route>
                <Route
                  path="/user/view/seller/:id"
                  element={<ViewSeller></ViewSeller>}
                ></Route>
                <Route
                  path="/user/view/student/:id"
                  element={<ViewStudent></ViewStudent>}
                ></Route>
                <Route
                  path="/user/edituser/:id"
                  element={<EditUser></EditUser>}
                ></Route>
                <Route
                  path="/user/editorg/:id"
                  element={<EditOrganization></EditOrganization>}
                ></Route>
                <Route path="/worship-centers" element={<JCGN />}></Route>
                <Route
                  path="/worship-centers/create-new-worship-center"
                  element={<CreateNewWorship />}
                ></Route>
                <Route
                  path="/worship-centers/view/:id"
                  element={<ViewWorship />}
                ></Route>
                <Route
                  path="/worship-centers/edit/:id"
                  element={<EditWorshipCenter />}
                ></Route>

                <Route
                  path="/video-management"
                  element={<VideoManagement />}
                ></Route>
                <Route
                  path="/video-management/edit-video/:id"
                  element={<EditVideo />}
                ></Route>
                <Route
                  path="/video-management/view-video/:id"
                  element={<ViewVideo />}
                ></Route>
                <Route path="/add-new-video" element={<AddNewVideo />}></Route>
                <Route
                  path="/audio-management"
                  element={<AudioManagement />}
                ></Route>
                <Route
                  path="/audio-management/edit-audio/:id"
                  element={<EditAudio />}
                ></Route>
                <Route
                  path="/audio-management/view-audio/:id"
                  element={<ViewAudio />}
                ></Route>
                <Route path="/add-new-audio" element={<AddNewAudio />}></Route>
                <Route
                  path="/templates/create-template"
                  element={<CreateNewTemplate />}
                />
                <Route path="/templates" element={<Template />} />

                <Route
                  path="/template/view-template/:id"
                  element={<ViewTemplate />}
                />

                <Route
                  path="/feedback/package/:id"
                  element={<ViewFeedbackBlogs />}
                />

                <Route
                  path="/feedback/blog/:id"
                  element={<ViewFeedbackProducts />}
                />

                <Route
                  path="/template/edit-templates/:id"
                  element={<EditTemplate />}
                />

                {/* <Route
                  path="/membership/membershipCard"
                  element={<CreateNewMembership />}
                /> */}

                <Route path="/membership" element={<MembershipCard />} />

                <Route
                  path="/membership/create-membershipcard"
                  element={<CreateNewMembership />}
                />

                <Route
                  path="/membershipCard/view-membershipCard/:id"
                  element={<ViewMembershipCard />}
                />

                <Route
                  path="/membershipCard/edit-membershipCard/:id"
                  element={<EditMembershipCard />}
                />

                <Route path="/feedback" element={<Feedbacks />} />

                <Route
                  path="/feedback/create-feedback"
                  element={<CreateNewTemplate />}
                />

                <Route
                  path="/feedback/view-feedback/:id"
                  element={<ViewTemplate />}
                />

                <Route
                  path="/worship-service-management"
                  element={<AdminServiceManagement />}
                />
                <Route
                  path="/worship-service-management/create-worship-service"
                  element={<CreateNewAdminService />}
                />
                <Route
                  path="/worship-service-management/view-worship-service/:id"
                  element={<ViewWorshipService />}
                />
                <Route
                  path="/worship-service-management/edit-worship-service/:id"
                  element={<EditWorshipService />}
                />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blogs/create-blog" element={<CreateNewBlog />} />
                <Route path="/blogs/view-blog/:id" element={<ViewBlog />} />
                <Route path="/blogs/edit-blog/:id" element={<EditBlog />} />

                <Route path="/afyaborabookings" element={<AfyaBora />} />

                <Route
                  path="/burialexpensesclaim"
                  element={<BurialExpences />}
                />

                <Route
                  path="/burialexpensesclaim/viewburialexpensesclaim/:id"
                  element={<BurialExpencesView />}
                />

                <Route
                  path="/burialexpensesclaim/editburialexpensesclaim/:id"
                  element={<BurialExpencesEdit />}
                />

                <Route
                  path="/burialexpensesclaim/addburialexpenses"
                  element={<BurialExpencesAdd />}
                />

                <Route
                  path="/artist-management"
                  element={<ArtistManagement />}
                />
                <Route
                  path="/artist-management/create-artist"
                  element={<CreateNewArtist />}
                />
                <Route
                  path="/artist-management/view-artist/:id"
                  element={<ViewArtist />}
                />
                <Route
                  path="/artist-management/edit-artist/:id"
                  element={<EditArtist />}
                />
                <Route path="/event/view/:id" element={<ViewEvents />} />
                <Route path="/cms/view/:id" element={<ViewContent />} />
                <Route path="/sermons/view/:id" element={<ViewSermons />} />
                <Route path="/sermons/edit/:id" element={<EditSermons />} />
                <Route path="/vote-management" element={<Votes />} />
                <Route
                  path="/vote-management/create-vote"
                  element={<CreateNewQuestion />}
                />
                <Route path="/votes/view-vote/:id" element={<ViewQuestion />} />
                <Route path="/votes/edit-vote/:id" element={<EditQuestion />} />

                <Route
                  path="/hospitalsAndHealthCenters"
                  element={<HospitalsAndHealthCentersManagement />}
                />
                <Route
                  path="/hospitalsAndHealthCenters/add"
                  element={<AddHospitalsAndHealthCenters />}
                />
                <Route
                  path="/hospitalsAndHealthCenters/view/:id"
                  element={<ViewHospitalsAndHealthCenters />}
                />
                <Route
                  path="/hospitalsAndHealthCenters/edit/:id"
                  element={<EditHospitalsAndHealthCenters />}
                />

                <Route
                  path="/maladiesAndConditionsManagement"
                  element={<MaladiesAndConditionsManagement />}
                />
                <Route
                  path="/maladiesAndConditions/add"
                  element={<AddMaladiesAndConditions />}
                />
                <Route
                  path="/maladiesAndConditions/view/:id"
                  element={<ViewMaladiesAndConditions />}
                />
                <Route
                  path="/maladiesAndConditions/edit/:id"
                  element={<EditMaladiesAndConditions />}
                />

                <Route
                  path="/coveredCareServicesManagement"
                  element={<CoveredCareServicesManagement />}
                />
                <Route
                  path="/coveredCareServices/add"
                  element={<AddCoveredCareServices />}
                />
                <Route
                  path="/coveredCareServices/view/:id"
                  element={<ViewCoveredCareServices />}
                />
                <Route
                  path="/coveredCareServices/edit/:id"
                  element={<EditCoveredCareServices />}
                />

                <Route
                  path="/doctorManagement"
                  element={<DoctorManagement />}
                />
                <Route path="/doctor/add" element={<AddDoctor />} />
                <Route path="/doctor/view/:id" element={<ViewDoctor />} />
                <Route path="/doctor/edit/:id" element={<EditDoctor />} />

                <Route
                  path="/pharmacyManagement"
                  element={<PharmacyManagement />}
                />
                <Route path="/pharmacy/add" element={<AddPharmacy />} />
                <Route path="/pharmacy/view/:id" element={<ViewPharmacy />} />
                <Route path="/pharmacy/edit/:id" element={<EditPharmacy />} />

                <Route
                  path="/specialistDoctorManagement"
                  element={<SpecialistDoctorManagement />}
                />
                <Route
                  path="/specialistDoctor/add"
                  element={<AddSpecialistDoctor />}
                />
                <Route
                  path="/specialistDoctor/view/:id"
                  element={<ViewSpecialistDoctor />}
                />
                <Route
                  path="/specialistDoctor/edit/:id"
                  element={<EditSpecialistDoctor />}
                />

                <Route
                  path="/feeSchedulesForHospitalServicesManagement"
                  element={<FeeSchedulesForHospitalServicesManagement />}
                />
                <Route
                  path="/feeSchedulesForHospitalServices/add"
                  element={<AddFeeSchedulesForHospitalServices />}
                />
                <Route
                  path="/feeSchedulesForHospitalServices/view/:id"
                  element={<ViewFeeSchedulesForHospitalServices />}
                />
                <Route
                  path="/feeSchedulesForHospitalServices/edit/:id"
                  element={<EditFeeSchedulesForHospitalServices />}
                />

                <Route
                  path="/feeScheduleForMedicationManagement"
                  element={<FeeScheduleForMedicationManagement />}
                />
                <Route
                  path="/feeScheduleForMedication/add"
                  element={<AddAddFeeScheduleForMedication />}
                />
                <Route
                  path="/feeScheduleForMedication/view/:id"
                  element={<ViewFeeScheduleForMedication />}
                />
                <Route
                  path="/feeScheduleForMedication/edit/:id"
                  element={<EditFeeScheduleForMedication />}
                />

                <Route
                  path="/coveredMedicationsAndDrugsManagement"
                  element={<CoveredMedicationsAndDrugsManagement />}
                />
                <Route
                  path="/coveredMedicationsAndDrugs/add"
                  element={<AddCoveredMedicationsAndDrugs />}
                />
                <Route
                  path="/coveredMedicationsAndDrugs/view/:id"
                  element={<ViewCoveredMedicationsAndDrugs />}
                />
                <Route
                  path="/coveredMedicationsAndDrugs/edit/:id"
                  element={<EditCoveredMedicationsAndDrugs />}
                />

                <Route
                  path="/funeralsAndMortuariesHomesManagement"
                  element={<FuneralsAndMortuariesHomesManagement />}
                />
                <Route
                  path="/funeralsAndMortuariesHomes/add"
                  element={<AddFuneralsAndMortuariesHomes />}
                />
                <Route
                  path="/funeralsAndMortuariesHomes/view/:id"
                  element={<ViewFuneralsAndMortuariesHomes />}
                />
                <Route
                  path="/funeralsAndMortuariesHomes/edit/:id"
                  element={<EditFuneralsAndMortuariesHomes />}
                />

                <Route
                  path="/feeSchedulesForFuneralHomeManagement"
                  element={<FeeSchedulesForFuneralHomeManagement />}
                />
                <Route
                  path="/feeSchedulesForFuneralHome/add"
                  element={<AddFeeSchedulesForFuneralHome />}
                />
                <Route
                  path="/feeSchedulesForFuneralHome/view/:id"
                  element={<ViewFeeSchedulesForFuneralHome />}
                />
                <Route
                  path="/feeSchedulesForFuneralHome/edit/:id"
                  element={<EditFeeSchedulesForFuneralHome />}
                />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
