import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const InvoiceManagement = () => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Fetch invoice data from API
  const fetchInvoices = async () => {
    try {
      const response = await fetch(
        "https://jcgnapi.hasthiya.org/invoiceform/getAllInvoiceforms"
      );
      const responseData = await response.json();

      if (responseData.status) {
        // Map and handle null or empty values
        const modifiedData = responseData.result.map((item) => ({
          id: item.id,
          clientName: item.clientName || "N/A",
          email: item.email || "N/A",
          invoicenumber: item.invoicenumber || "N/A",
          organizationName: item.organizationName || "N/A",
          totalPrice: item.totalPrice || "0",
          date: item.date ? item.date.split("T")[0] : "N/A",
        }));

        // Sort data by descending ID
        modifiedData.sort((a, b) => b.id - a.id);

        setData(modifiedData);
      } else {
        console.error("Failed to fetch invoices:", responseData.message);
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  // Export table data to PDF
  const exportToPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "Invoice ID",
          "Client Name",
          "Email",
          "Invoice Number",
          "Organization",
          "Total Price",
          "Date",
        ],
      ],
      body: data.map(
        ({
          id,
          clientName,
          email,
          invoicenumber,
          organizationName,
          totalPrice,
          date,
        }) => [
          id,
          clientName,
          email,
          invoicenumber,
          organizationName,
          totalPrice,
          date,
        ]
      ),
    });
    doc.save("invoices_data.pdf");
  };

  // Handle delete invoice action
  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this invoice!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          `https://jcgnapi.hasthiya.org/invoiceform/deleteInvoiceformByID/${id}`,
          { method: "DELETE" }
        )
          .then((response) => {
            if (!response.ok) throw new Error("Failed to delete invoice");

            setData(data.filter((item) => item.id !== id));
            Swal.fire("Deleted!", "The invoice has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting invoice:", error);
            Swal.fire(
              "Error!",
              "Failed to delete invoice. Please try again later.",
              "error"
            );
          });
      }
    });
  };

  // Define table columns
  const columns = [
    { field: "id", headerName: "Invoice ID", width: 100 },
    { field: "clientName", headerName: "Client Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "invoicenumber", headerName: "Invoice Number", flex: 1 },
    { field: "organizationName", headerName: "Organization", flex: 1 },
    { field: "totalPrice", headerName: "Total Price", flex: 1 },
    { field: "date", headerName: "Date (YYYY/MM/DD)", flex: 1 },
    {
      field: "Actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <Link to={`/invoice/view/${params.row.id}`}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="-10px"
      >
        <Header title="Invoice Management" subtitle="Managing invoices" />
      </Box>
      <Box
        m="10px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Button
          variant="contained"
          onClick={exportToPdf}
          sx={{
            backgroundColor: "#4caf50",
            color: "white",
            fontSize: "10px",
            "&:hover": {
              backgroundColor: "#388e3c",
            },
          }}
        >
          Export as PDF
        </Button>
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default InvoiceManagement;
