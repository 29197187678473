import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";

function ViewCareer() {
  const { id } = useParams();
  const [careerDetails, setCareerDetails] = useState(null);

  useEffect(() => {
    const fetchCareerDetails = async () => {
      try {
        const response = await axios.get(
          `https://jcgnapi.hasthiya.org/api/career/get/${id}`
        );
        const responseData = response.data;

        if (responseData.result.status) {
          const data = responseData.result.data;
          const formattedDetails = {
            "I. Employer Details": {
              "Company/Organization Name": data.name,
              "Company/Employer Email": data.companyEmail,
              "Company/Organization Description": data.description,
            },
            "II. Job Details": {
              "Job Title": data.jobTitle,
              "Job Location": data.jobLocation,
              "Job Description": data.jobDescription,
            },
            "III. Duties and Responsibilities": {
              "Duties and Responsibilities": data.duty,
              "Job Qualifications": data.qualification,
              "Key Competencies": data.keyCompetencies,
            },
            "IV. Final Details": {
              "Physical Requirements": data.physicalRequirement,
              Others: data.other,
              "Time Duration Show in Web": data.duration,
            },
          };
          setCareerDetails(formattedDetails);
        } else {
          console.error("Error fetching career:", responseData.result.message);
          setCareerDetails(null);
        }
      } catch (error) {
        console.error("Error fetching career:", error);
        setCareerDetails(null);
      }
    };

    fetchCareerDetails();
  }, [id]);

  if (!careerDetails) {
    return <Typography variant="h5">Loading...</Typography>;
  }

  return (
    <Box m="20px" height="75vh" overflow="auto" paddingRight="20px">
      <Header title={`View Career ${id}`} subtitle="Status On or Off" />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(careerDetails).map(([section, details]) => (
            <React.Fragment key={section}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2" fontWeight={600} mt={1}>
                  {section}
                </Typography>
              </Grid>
              {Object.entries(details).map(([field, value]) => (
                <React.Fragment key={field}>
                  <Grid item xs={2}>
                    <Typography
                      variant="h6"
                      component="span"
                      fontWeight="bold"
                      mt={3}
                    >
                      {field}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6" component="span" mt={3} mr={2}>
                      :{value}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewCareer;
