import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const PaymentLogs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://jcgnapi.hasthiya.org/payment/payment-history?userId="
        );
        if (response.data.status) {
          setData(response.data.result);
        }
      } catch (error) {
        console.error("Error fetching payment logs data:", error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "userId", headerName: "User ID", flex: 0.5 },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      // cellClassName: "name-column--cell",
      // renderCell: (params) => {
      //   const handleOpen = (event) => setAnchorEl(event.currentTarget);
      //   const handleClose = () => setAnchorEl(null);

      //   return (
      //     <Box display="flex" alignItems="center" gap={1}>
      //       {params.value === "Donation" ? (
      //         <>
      //           <Typography
      //             sx={{
      //               fontSize: "14px",
      //               color: "greenAccent[300]",
      //               cursor: "pointer",
      //             }}
      //             onClick={handleOpen}
      //           >
      //             {params.value}
      //           </Typography>
      //           <ExpandMoreIcon
      //             sx={{
      //               cursor: "pointer",
      //               color: "greenAccent[300]",
      //             }}
      //             onClick={handleOpen}
      //           />
      //           <Menu
      //             anchorEl={anchorEl}
      //             open={Boolean(anchorEl)}
      //             onClose={handleClose}
      //           >
      //             <MenuItem>
      //               Donation Type:{" "}
      //               <b>
      //                 {params.row.metadata?.donationType || "Not Available"}
      //               </b>
      //             </MenuItem>
      //           </Menu>
      //         </>
      //       ) : (
      //         <Typography>{params.value}</Typography>
      //       )}
      //     </Box>
      //   );
      // },
    },
    {
      field: "donationType",
      headerName: "Donation Type",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {params.row.metadata?.donationType || "Not Available"}
        </Typography>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      type: "date",
      valueGetter: (params) => new Date(params.value),
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View Details">
            <IconButton onClick={() => handleViewClick(params.row.id)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  // const handleViewClick = (userId) => {
  //   navigate(`/paymentlogs/view/${userId}`);
  // };

  const handleViewClick = (id) => {
    navigate(`/paymentlogsbyID/view/${id}`);
  };

  const exportToPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [["ID", "User ID", "Description", "Amount", "Status", "Date"]],
      body: data.map(({ id, userId, description, amount, status, date }) => [
        id,
        userId,
        description,
        amount,
        status,
        new Date(date).toLocaleDateString(),
      ]),
    });
    doc.save("payment_logs.pdf");
  };

  const getRowClassName = () => {
    return "row-divider";
  };

  return (
    <Box m="20px">
      <Header title="Payment Logs Management" subtitle="" />
      <Box
        m="0 0 0 0"
        height="60vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .row-divider": {
            borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            variant="contained"
            onClick={exportToPdf}
            sx={{
              backgroundColor: "#4caf50",
              color: "white",
              fontSize: "10px",
              "&:hover": {
                backgroundColor: "#388e3c",
              },
            }}
          >
            Export to PDF
          </Button>
        </Box>
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowClassName={getRowClassName}
        />
      </Box>
    </Box>
  );
};

export default PaymentLogs;
